.Main {
    display: flex;
    height: 100%;
    font-family: 'Pretendard Variable';
    background-color: var(--main-color);

    .right_wrapper {
        // width: calc(100% - 250px);
        width: 100%;
        margin-top: 50px;

        @media (max-width: 1370px) {
            width: 100%;
        }

        @media (max-width: 600px) {
            height: 100%;
            margin-top: 0;
        }

        .login_wrapper {
            width: 100%;
            margin-top: 30px;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            color: #777777;
            font-size: 18px;

            .join_box {
                margin-right: 32px;

            }
        }

        .ex_content_wrapper {
            display: flex;
            justify-content: center;
            height: calc(100% - 100px);

            @media (max-width: 600px) {
                height: 100%;
            }

            .ex_content_box {
                margin-top: 50px;
                border: 1.5px solid var(--border-color);
                height: 90%;
                width: 90%;
                border-radius: 20px;
                background-color: var(--sub-sub-color);
                // background-color: #f5feff;
                overflow-y: scroll;

                @media (max-width: 600px) {
                    margin-top: 70px;
                    height: calc(100% - 90px);
                }

                .gpt_answer_wrapper {
                    margin: 2rem 3rem;

                    @media(max-width:600px) {
                        margin: 1rem 1.5rem;
                    }

                    hr {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                        border: 1px solid #DEDEDE;
                    }


                    .category_btn_wrapper {
                        display: flex;
                        gap: 30px;



                        .category_btn_selected {
                            padding: 10px 20px;
                            border-radius: 20px;
                            background-color: #007ce3;
                            color: white;
                            font-size: 18px;
                            font-weight: 500;
                            cursor: pointer;
                            transition: 0.3s;

                            &:hover {
                                background-color: #81a7bf;
                            }
                        }
                    }

                    .gpt_answer_box {


                        .gpt_editing {

                            .gpt_editing_title {
                                align-items: center;
                                margin-bottom: 1rem;

                                h2 {
                                    font-size: 20px;
                                    font-weight: 500;
                                }

                                h3 {
                                    font-size: 18px;
                                    font-weight: 400;
                                    margin-bottom: 12px;
                                    text-decoration: underline;
                                    color: var(--font-color);

                                }


                                p {
                                    // color: #777777;
                                    font-size: 16px;
                                    margin-left: 12px;
                                    color: var(--font-color);
                                }
                            }

                            .gpt_editing_content {
                                h3 {
                                    font-size: 18px;
                                    font-weight: 400;
                                    margin-bottom: 12px;
                                    text-decoration: underline;
                                    color: var(--font-color);
                                }

                                .not_edited_box {
                                    margin-bottom: 1rem;

                                    p {
                                        font-size: 16px;
                                    }

                                    .content_box {
                                        background-color: #e5e5e5;
                                        line-height: 24px;
                                        padding: 20px;
                                        border-radius: 10px;
                                    }

                                }

                                .edited_box {
                                    display: flex;
                                    margin-bottom: 12px;

                                    img {
                                        width: 35px;
                                        height: 35px;
                                        margin-right: 10px;

                                        @media(max-width:450px) {
                                            display: none;
                                        }
                                    }

                                    .edited_content {
                                        background-color: #d5e3eb;
                                        line-height: 24px;
                                        padding: 20px;
                                        border-radius: 10px;
                                    }
                                }

                            }
                        }
                    }

                }


            }

            .ex_content_box::-webkit-scrollbar {
                width: 10px;
            }

            .ex_content_box::-webkit-scrollbar-thumb {
                background-color: #81a7bf;
                border-radius: 10px;
                background-clip: padding-box;
                border: 2px solid transparent;
            }

            .ex_content_box::-webkit-scrollbar-track {
                background-color: var(--scrol-color);
                border-radius: 10px;
                // box-shadow: inset 0px 0px 5px white;
            }

            .ex_content_box::-webkit-scrollbar-button:vertical:start:decrement {
                display: block;
                height: 75px;
            }

            .ex_content_box::-webkit-scrollbar-button:vertical:end:decrement {
                display: block;
                height: 15px;
            }

        }
    }

}