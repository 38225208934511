.Main {
    display: flex;
    height: 100%;
    font-family: 'Pretendard Variable';
    background-color: var(--main-color);

    .right_wrapper {
        // width: calc(100% - 250px);
        width: 100%;
        margin-top: 50px;

        @media (max-width: 1370px) {
            width: 100%;
        }

        @media (max-width: 600px) {
            margin: 0;
        }

        .login_wrapper {
            width: 100%;
            margin-top: 30px;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            color: #777777;
            font-size: 18px;

            .join_box {
                margin-right: 32px;

            }
        }

        .ex_content_wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            height: calc(100% - 100px);

            @media (max-width: 600px) {
                height: 100%;
            }


            .ex_content_box {
                margin-top: 50px;
                border: 1.5px solid var(--border-color);
                height: 90%;
                width: 90%;
                border-radius: 20px;
                background-color: var(--sub-sub-color);
                // background-color: #f5feff;
                overflow-y: scroll;

                @media (max-width: 600px) {
                    margin-top: 70px;
                    height: calc(100% - 90px);
                }

                .gpt_answer_wrapper {

                    .category_btn_wrapper_wrapper {
                        position: absolute;
                        width: 90%;
                        background-color: var(--sub-sub-color);
                        // background-color: white;
                        top: 50px;
                        border-radius: 20px 20px 0 0;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #999;

                        @media (max-width: 600px) {
                            top: 70px;
                        }


                        @media (max-width: 540px) {
                            height: 60px;
                        }

                        .category_btn_wrapper {
                            display: flex;
                            gap: 30px;
                            margin-left: 50px;

                            @media (max-width: 900px) {
                                gap: 20px;
                                margin-left: 30px;
                            }


                            @media (max-width: 600px) {
                                gap: 0;
                                margin-left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 20px 20px 0 0;
                                overflow: hidden;
                            }

                            .category_btn_box {
                                padding: 10px 20px;
                                border-radius: 20px;
                                background-color: #81a7bf;
                                color: white;
                                font-size: 18px;
                                font-weight: 500;
                                cursor: pointer;
                                transition: 0.3s;
                                display: flex;
                                align-items: center;


                                @media (min-width: 900px) {
                                    &:hover {
                                        background-color: #5b7f9d;
                                    }
                                }

                                @media (max-width: 900px) {
                                    font-size: 16px;
                                    padding: 8px 16px;
                                }

                                @media (max-width: 600px) {
                                    width: 100%;
                                    border-radius: 0;
                                    justify-content: center;
                                    // border: 1px solid;
                                    border-right: 1px solid;
                                }


                                @media (max-width: 540px) {
                                    font-size: 14px;
                                }


                                @media (max-width: 500px) {
                                    padding: 6px 6px;
                                }


                            }

                            .category_btn_selected {
                                padding: 10px 20px;
                                border-radius: 20px;
                                background-color: #007ce3;
                                color: white;
                                font-size: 18px;
                                font-weight: 500;
                                cursor: pointer;
                                transition: 0.3s;
                                display: flex;
                                align-items: center;
                                border: var(--border-color);

                                &:hover {
                                    // background-color: #81a7bf;
                                }

                                @media (max-width: 900px) {
                                    font-size: 16px;
                                    padding: 8px 16px;
                                }

                                @media (max-width: 600px) {
                                    width: 100%;
                                    border-radius: 0;
                                    justify-content: center;
                                    border-right: 1px solid;
                                }

                                @media (max-width: 540px) {
                                    font-size: 14px;
                                }

                                @media (max-width: 500px) {
                                    padding: 6px 6px;
                                }
                            }
                        }
                    }




                    .gpt_answer_box {
                        margin: 6rem 3rem 0 3rem;

                        @media (max-width: 600px) {
                            margin: 5.5rem 1.5rem 0 1.5rem;

                        }


                        p {
                            @media (max-width: 900px) {
                                font-size: 14px;
                            }

                        }

                        h2 {
                            font-size: 20px;
                            font-weight: 500;
                            color: var(--font-color);

                            @media (max-width: 900px) {
                                font-size: 18px;
                            }

                        }

                        h3 {
                            font-size: 18px;
                            font-weight: 400;
                            margin-bottom: 12px;
                            text-decoration: underline;
                            color: var(--font-color);

                            @media (max-width: 900px) {
                                font-size: 16px;
                            }
                        }


                        .gpt_evaluate {

                            .gpt_evaluate_title {

                                display: flex;
                                align-items: center;
                                margin-bottom: 1rem;


                                p {
                                    color: #777777;
                                    font-size: 14px;
                                    margin-left: 12px;
                                }
                            }

                            .gpt_evaluate_score {


                                .score_box,
                                .why_box {
                                    display: flex;
                                    margin-bottom: 12px;

                                    img {
                                        width: 35px;
                                        height: 35px;
                                        margin-right: 10px;
                                    }

                                    .content_box {
                                        // background-color: #d5e3eb;
                                        background-color: #e8eced;
                                        line-height: 24px;
                                        padding: 20px;
                                        border-radius: 10px;

                                        h3 {
                                            font-size: 18px;
                                            text-decoration: none;
                                            font-weight: 600;
                                            padding: 4px 16px;
                                            border-radius: 12px;
                                            width: 140px;

                                            @media (max-width: 900px) {
                                                font-size: 16px;
                                                padding: 2px 14px;
                                                width: 120px;
                                            }
                                        }
                                    }

                                }
                            }
                        }

                        .gpt_editing {

                            .gpt_editing_title {
                                display: flex;
                                align-items: center;
                                margin-bottom: 1rem;

                                // h2 {
                                //     font-size: 20px;
                                //     font-weight: 500;
                                // }

                                p {
                                    color: #777777;
                                    font-size: 14px;
                                    margin-left: 12px;
                                }
                            }

                            .gpt_editing_content {
                                // h3 {
                                //     font-size: 18px;
                                //     font-weight: 400;
                                //     margin-bottom: 12px;
                                //     text-decoration: underline;
                                // }

                                .not_edited_box {
                                    margin-bottom: 1rem;

                                    // p {
                                    //     font-size: 16px;
                                    // }

                                    .content_box {
                                        background-color: #e5e5e5;
                                        line-height: 24px;
                                        padding: 20px;
                                        border-radius: 10px;
                                    }

                                }

                                .edited_box {
                                    display: flex;
                                    margin-bottom: 12px;

                                    img {
                                        width: 35px;
                                        height: 35px;
                                        margin-right: 10px;

                                        @media (max-width: 500px) {
                                            display: none;
                                        }
                                    }

                                    .edited_content {
                                        background-color: #d5e3eb;
                                        line-height: 24px;
                                        padding: 20px;
                                        border-radius: 10px;


                                        span {
                                            background: #aff7af;
                                        }
                                    }
                                }

                            }
                        }
                    }

                }


            }

            .ex_content_box::-webkit-scrollbar {
                width: 10px;
            }

            .ex_content_box::-webkit-scrollbar-thumb {
                background-color: #81a7bf;
                border-radius: 10px;
                background-clip: padding-box;
                border: 2px solid transparent;
            }

            .ex_content_box::-webkit-scrollbar-track {
                background-color: var(--scrol-color);
                border-radius: 10px;
                // box-shadow: inset 0px 0px 5px white;
            }

            .ex_content_box::-webkit-scrollbar-button:vertical:start:decrement {
                display: block;
                height: 75px;
            }

            .ex_content_box::-webkit-scrollbar-button:vertical:end:decrement {
                display: block;
                height: 15px;
            }


        }
    }

}