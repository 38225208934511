:root {
    --main-color: #ffffff;
    --sub-color: #f9f9f9;
    --sub-sub-color: #f5feff;
    --font-color: black;
    --scroll-color: #81a7bf;
    --border-color: #DEDEDE;
}

[data-theme="dark"] {
    --main-color: #343541;
    --sub-color: #202123;
    --sub-sub-color: #504D5E;
    --font-color: white;
    --scroll-color: #a5b0c7;
    --border-color: #504D5E;

}

[data-theme="white"] {
    --main-color: #ffffff;
    --sub-color: #f9f9f9;
    --sub-sub-color: #f5feff;
    --font-color: black;
    --scroll-color: #81a7bf;
    --border-color: #DEDEDE;
}