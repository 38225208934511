.left_bar {
    min-width: 260px;
    width: 14%;
    height: 100%;
    background-color: var(--sub-color);
    position: relative;
    transition: width 0.5s ease, min-width 0.5s ease;

    color: var(--font-color);

    &.folded {
        // display: none;
        min-width: 0;
        width: 0;
    }

    @media (max-width: 1370px) {
        position: absolute;
        width: 300px;
        z-index: 2;
    }

    @media (max-width: 600px) {
        width: 250px;
    }



    // padding: 30px;

    .logo_wrapper {
        display: flex;
        align-items: center;
        // justify-content: center;
        // padding: 30px;
        margin-top: 1rem;
        height: 50px;
        // position: absolute;
        // width: 100%;
        // overflow: hidden;
        position: fixed;
        padding: 0 15px;


        @media (max-width:600px){
            margin-top: 0;
            height: 70px;
        }


        .fold_wrapper {
            // position: fixed;
            // min-width: 25px;
            // height: 100px;
            // left: 280px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            // top: calc(50% - 50px);
            transition: left 0.5s ease;
            width: 50px;
            height: 50px;

            img {
                width: 22px;
                height: 22px;
                
                @media (max-width: 900px) {
                    width: 18px;
                    height: 18px;
                }
            }

            &:hover {

                .fold_box {
                    background-color: #81a7bf;
                }
            }


            @media (max-width: 1370px) {
                left: 30px;
            }

            &.folded {

            }

            .fold_box {
                width: 5px;
                height: 40px;
                background-color: #e3e3e3;
                border-radius: 15px;


            }


        }



        .title_box {
            display: flex;
            justify-content: center;
            margin-left: 1rem;

            img {
                height: 30px;
                
                @media (max-width: 900px) {
                    height: 25px;
                }
                
            }
        }






    }

    .history_wrapper {
        margin-top: 5rem;
        height: calc(100% - 5rem);
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #81a7bf;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--scroll-color);
            border-radius: 10px;
            // box-shadow: inset 0px 0px 5px white;
        }


        .history_job,
        .history_talent {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 1rem;

            h2 {
                margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                color: #999898;
                white-space: nowrap;
            }

            .history_block {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                border-radius: 8px;

                h4 {
                    margin-left: 30px;
                    font-size: 16px;
                    white-space: nowrap;
                }

                &:hover {
                    background-color: #E5E5E5;
                    cursor: pointer;
                }

            }
        }
    }
}