.Main {
    display: flex;
    height: 100%;
    font-family: 'Pretendard Variable';
    background-color: var(--main-color);

    .right_wrapper {
        // width: calc(100% - 250px);
        width: 100%;
        margin-top: 50px;

        @media (max-width: 1370px) {
            width: 100%;
        }

        @media (max-width:600px){
            margin-top: 70px;
        }

        .login_wrapper {
            width: 100%;
            margin-top: 30px;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            color: #777777;
            font-size: 18px;

            .join_box {
                margin-right: 32px;

            }
        }

        .ex_content_wrapper {
            display: flex;
            justify-content: center;
            height: calc(100% - 100px);

            @media (max-width:600px){
                height: 100%;
            }


            .ex_content_box {
                margin-top: 50px;
                border: 3px solid #DEDEDE;
                height: 95%;
                width: 90%;
                border-radius: 20px;
                background-color: var(--main-color);
                overflow-y: scroll;
                overflow-x: hidden;
                color: var(--font-color);

                @media (max-width:600px){
                    margin-top: 0;
                    // border: 0;
                    height: 98%;
                }

                

                .job_input_wrapper {
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 auto;


                    .job_input_box {
                        margin-top: 1.5rem;
                        width: 100%;

                        h2 {
                            font-size: 24px;
                            font-weight: 500;

                            @media (max-width: 1370px) {
                                font-size: 18px;
                            }
                        }

                        .text_wrapper {
                            display: flex;

                            textarea {
                                width: 100%;
                                height: 170px;
                                margin-top: 20px;
                                padding: 10px;
                                border: 1px solid #DEDEDE;
                                border-radius: 10px;
                                resize: none;
                                font-family: 'Pretendard Variable';
                                font-size: 16px;

                                @media (max-width: 1370px) {}
                            }

                            textarea:focus {
                                border-color: #86b7fe;
                                box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, .25);
                                outline: 0;
                            }

                            textarea::-webkit-scrollbar {
                                width: 10px;
                            }

                            textarea::-webkit-scrollbar-thumb {
                                background-color: #81a7bf;
                                border-radius: 10px;
                                background-clip: padding-box;
                                border: 2px solid transparent;
                            }

                            textarea::-webkit-scrollbar-track {
                                background-color: var(--scroll-color);
                                border-radius: 10px;
                                box-shadow: inset 0px 0px 5px white;
                            }
                        }


                        .charCount {
                            text-align: right;
                            color: #888;
                            font-size: 0.9em;
                            margin-top: 10px;
                            width: 99%;
                            font-size: 16px;
                            font-weight: 500;


                            &.warning {
                                color: red;
                            }
                        }

                    }


                }

                .submit_btn_wrapper {
                    display: flex;
                    justify-content: flex-end;
                    width: 90%;
                    margin: 0 auto;

                    .submit_btn_box {
                        color: white;
                        width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        background-color: #aa91bf;
                        border: 0;
                        font-size: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        margin-top: 1.5rem;
                        margin-bottom: 2rem;

                        @media(max-width:600px){
                            font-size: 16px;
                            width: 80px;
                            height: 35px;
                        }

                    }

                    .submit_btn_box:hover {
                        background-color: #4491c3;
                    }

                }



            }

            .ex_content_box::-webkit-scrollbar {
                width: 10px;
                margin-left: 20px;
            }

            .ex_content_box::-webkit-scrollbar-thumb {
                background-color: var(--scroll-color);
                border-radius: 10px;
                background-clip: padding-box;
                border: 2px solid transparent;
            }

            .ex_content_box::-webkit-scrollbar-track {
                display: none;
                background-color: #f5feff;
                border-radius: 10px;
                box-shadow: inset 0px 0px 5px white;
            }

        }
    }

}


.response_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    .response_modal_box {
        width: 300px;
        height: 200px;
        // background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        // color: #777777;
        padding: 20px;
        color: white;

        video {
            width: 350px;
            height: 350px;
            z-index: -1;
            position: absolute;
            // overflow: hidden;
            border-radius: 30px;
        }

        p {
            font-size: 60px;
            margin-top: 12px;
        }

        h2 {
            font-size: 30px;
        }


        .loading_wrapper {
            margin: 0 auto;
            text-align: center;
            margin-bottom: 24px;

            .loading_box {
                width: 50px;
                height: 50px;
                margin: 10px auto;
                border: 10px solid #bfbfbf;
                border-radius: 10%;
                animation: load 3s linear infinite;
            }

            @keyframes load {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

    }
}